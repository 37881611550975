import React from "react"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import ContentWrapper from "@sections/ContentWrapper"
import BottomLine from "@components/BottomLine"

const title = "Welcome"

export default function Home() {
  return (
    <Layout pageClass="signin">
      <SEO title={title} />
      <ContentWrapper>
        <div className="col-md-3 centered signin-card">
          {/* eslint-disable-next-line */}
          <form id="sign_in" method="post" action="javascript:void(0);">
            <input type="text" placeholder="Username" name="user[name]" />
            <span className="message">We did not recognize this user.</span>
            <input
              type="password"
              placeholder="Password"
              name="user[password]"
            />
            <button className="button signin-button">
              <i className="button-icon fa fa-envelope" aria-hidden="true"></i>
              <span>Sign in</span>
            </button>
            <br />
          </form>
          <div className="card"></div>
        </div>
        <div className="clearfix"></div>
        <div className="signin-info-wrapper">
          <div className="signin-info text-center col-sm-12 fadeIn animated">
            <img
              src="images/logo.png"
              alt="Alfa Consulting's logo"
              className="logo"
            />
          </div>
        </div>

        <h2 className="glow in page-tlt citation">
          "Unique problems require unique solutions"
        </h2>
      </ContentWrapper>
      <BottomLine />
    </Layout>
  )
}
